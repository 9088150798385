.contact{
    height: 105vh;
    position: relative;
    
}
.c-bg{
    width: 20px;
    height: 100%;
    background-color: #59b256;
    position: absolute;
}

.c-wrapper{
    padding: 50px;
    display: flex;

}

.c-left{
    flex:1;
}
.c-right{
    flex:1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.c-title{
    font-size: 60px;
    width: 80%;
}

.c-info-item{
    display: flex;
    align-items: center;
    margin: 50px 0px;
}

.c-icon{
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

.c-right > form{
 margin-top: 20px;
}


input{
    width: 50%;
    height: 50px;
    border: none;
    border-bottom: 1px solid gray;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
}
textarea{
    width: 100%;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
    
}

button{
    border:none;
    padding: 15px;
    background-color: #59b256;
    color: white;
    font-weight: 500;
    cursor: pointer;
}

@media screen and (max-width:480px) {
    .contact{
        height: 160vh;
    }
    .c-wrapper{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .c-title{
        font-size: 30px;
       text-align: center;
       width: 100%;
    }
    .c-info-item{
        display: flex;
        align-items: center;
        justify-content: center;
        margin:22px 0
    }
    .c-left{
        justify-content: center;
        text-align: center;
    }
    .c-right{
        width: 100%;
        flex-direction: column;
        margin-top: 35px;
    }
    .c-desc >p {
        line-height: 2rem;
        text-align: center;
    }
    .c-right > form{
        margin-top: 20px;
        align-self: center;
    }

    input{
        width: 50%;
        height: 40px;
        margin: 10px 25%;
        font-size: 12px;
        padding-left: 10px;
        justify-content: center;
    }
    textarea{
        width: 80%;
        margin: 10px 10%;
        font-size: 14px;
        padding-left: 10px;
        
    }
    button{
        margin: 20px 35%;
        width: 30%;
    }
}