.productList{
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.pl-text{
    width: 65%;
}

.pl-title{
    font-size: 50px;
    font-weight: 600;

}
.pl-desc{
    margin: 20px 0;
}

.pl-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%; 
}

@media screen and (max-width:480px) {
    .productList{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30px 20px;
    }
    .pl-text{
        width: 80%;
        align-items: center;
    }

    .pl-title{
        font-size: 35px;
        font-weight: 600;
    }
    
}