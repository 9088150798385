.toggle{
   height: 25px;
   width: 50px;
   border-radius: 20px;
   border:  1px solid #999;
   background-color: white;
   position: fixed;
   top: 10px;
   right: 10px;
   display: flex;
   align-items: center;
   justify-content: space-around;
   z-index: 22;
}
.t-icon{
width: 15px;
height: 15px;
}

.t-button{   
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: absolute;
    background-color: #999; 
    cursor: pointer;
    right: 0;
}
