.about{
   height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;
   overflow: hidden;
}
.a-left{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
}

.a-right{
    flex: 1;
}


.a-card.bg{
    position: absolute;
    top: 50px;
    left: 50px;
    background-color: #333;
}

.a-card{
    height: 70vh;
    width: 60%;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
}

.a-img{
 width: 90%;
 height: 95%;
 object-fit: cover;
 border-radius: 20px;
 overflow: hidden;
}

.a-award-img{
    width: 120px;
    height: 120px;
    border-radius: 20px;
}

.a-title{
    font-size: 35px;
    font-weight: 400;
}
.a-sub{
    margin: 15px 0px;
    font-weight: 400;
}

.a-desc{
    font-weight: 300;
    padding-right: 20px;
    
}

@media screen and (max-width:480px) {
    .about{
        flex-direction: column;
        text-align: center;
        margin-top: 40px;
    }
    .a-left{
        width: 100%;
    }
    .a-card{
        height: 40vh;
        border-radius: 30px;
        position: relative;
        overflow: hidden;
    }
    .a-right{
        padding: 20px;
    }

}