.product{
    
    width: 30%;
    height: 40vh;
    margin: 20px 10px;
    overflow: hidden;
    border-radius: 10px 10px 0px 0px;
    border:1px solid rgb(212, 211, 211);
}

.p-browser{
    height: 20px;
    display: flex;
    align-items: center;
    position: sticky;
    z-index: 2;
    background-color: rgb(212, 211, 211);
}

.p-circle{
    width: 6px;
    height: 3px;
    border-radius: 50%;
    margin:3px;
    background-color: white;
}

.prodImg{
    width: 100%;
    transition: all 10s ease-in-out;
}

.product:hover{
    transform: scale(1.1);
}

.prodImg:hover{
    transform: translateY(-50%);
}

@media screen and (max-width:780px) {
    .product{
        flex-direction: column;
        width: 40%;
        align-items: center;
    }
}
@media screen and (max-width:480px) {
    .product{
        flex-direction: column;
        width: 90%;
        align-items: center;
    }
}