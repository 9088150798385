.intro {
  display: flex;
  height: 100vh;
}

.i-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center; 
}

.i-right {
  flex: 1;
  position: relative;
  /* justify-content: center;
    align-items: center; */
}

.i-left-wrapper {
  padding: 50px;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.i-intro {
  font-size: 30px;
  font-weight: 300;
}

.i-name {
  font-size: 60px;
  font-weight: 400;
}

.i-title {
  height: 50px;
  overflow: hidden;
}
.i-title-wrapper {
  height: 100%;
  animation: move 6s ease-in-out infinite alternate;
}

@keyframes move {
  25% {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(-100px);
  }
  75% {
    transform: translateY(-105px);
  }
  100%{
        transform: translateY(-160px);
    }
}

.i-title-item {
  height: 50px;
  font-size: 30px;
  font-weight: bold;
  color: #59b256;
  display: flex;
  align-items: center;
}

/* .i-image {
  width: 60%;
  height: 0%;
  object-fit: cover;
  position: absolute;
} */
.i-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #59b256;
  clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
}

.i-scroll{
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 20px;
}

@media screen and (max-width:480px) {
  .intro{
    flex-direction: column;
  }
  .i-left {
    flex: 1.5;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .i-right{
    flex: 1;
  }
  .i-left-wrapper{
    align-items: center;
    justify-content: center;
    
  }
  .i-intro {
    font-size: 25px;
    font-weight: 300;
  }
  .i-title {
    height: 40px;
    
  }
  .i-title-wrapper {
    height: 100%;
    animation: move 6s ease-in-out infinite alternate;
  }
  .i-title-item {
    height: 40px;
    font-size: 25px;
    font-weight: bold;
    color: #59b256;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: center;
  }
  .i-desc{
    margin-top: 20px;
  }
}